import { Injectable, computed, signal } from "@angular/core";
import { ActionInterface, ModulesInterface, PermissionInterface } from "@common/ports/interfaces";
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ComponentsHelper {

  private modulesTemp = signal<ModulesInterface[]>([
    { id: '', code: 'dashboard', name: '', icon: 'home', routerLink: '/main/dashboard', children: [] },
    { id: '', code: 'user', name: '', icon: 'users', routerLink: '/main/user/list', children: [] },
    { id: '', code: 'rolesandpermissions', name: '', icon: 'id-card', routerLink: '/main/rolesandpermissions/list', children: [] },
    {
      id: '', code: 'cog', name: 'Configuración', icon: 'cog', routerLink: '/main/setting', children: [
        { id: '', code: 'country', name: '', icon: 'globe', routerLink: '/main/setting/country/list', children: [] },
        { id: '', code: 'state', name: '', icon: 'map', routerLink: '/main/setting/state/list', children: [] },
        { id: '', code: 'city', name: '', icon: 'map-marker', routerLink: '/main/setting/city/list', children: [] },
        { id: '', code: 'formspayment', name: '', icon: 'wallet', routerLink: '/main/setting/formspayment/list', children: [] },
        { id: '', code: 'deliverytypes', name: '', icon: 'truck', routerLink: '/main/setting/deliverytypes/list', children: [] },
      ]
    },
    { id: '', code: 'institutions', name: '', icon: 'building', routerLink: '/main/institutions/list', children: [] },
    { id: '', code: 'productsandservices', name: '', icon: 'shop', routerLink: '/main/productsandservices/list', children: [] },
    { id: '', code: 'clients', name: '', icon: 'users', routerLink: '/main/clients/list', children: [] },
    { id: '', code: 'sales', name: '', icon: 'money-bill', routerLink: '/main/sales/list', children: [] },
    { id: '', code: 'recognition', name: '', icon: 'microchip-ai', routerLink: '/main/recognition/home', children: [] },
    { id: '', code: 'inventory', name: '', icon: 'box', routerLink: '/main/inventory/list', children: [] },
    { id: '', code: 'suppliers', name: '', icon: 'truck ', routerLink: '/main/suppliers/list', children: [] },
  ]);
  private listPermissionTemp = signal<ModulesInterface[]>([
    { id: '', code: 'dashboard', name: '', icon: 'home', routerLink: '/main/dashboard', children: [] },
    { id: '', code: 'user', name: '', icon: 'users', routerLink: '/main/user/list', children: [] },
    { id: '', code: 'rolesandpermissions', name: '', icon: 'id-card', routerLink: '/main/rolesandpermissions/list', children: [] },
    { id: '', code: 'country', name: '', icon: 'globe', routerLink: '/main/setting/country/list', children: [] },
    { id: '', code: 'state', name: '', icon: 'map', routerLink: '/main/setting/state/list', children: [] },
    { id: '', code: 'city', name: '', icon: 'map-marker', routerLink: '/main/setting/city/list', children: [] },
    { id: '', code: 'formspayment', name: '', icon: 'wallet', routerLink: '/main/setting/formspayment/list', children: [] },
    { id: '', code: 'deliverytypes', name: '', icon: 'truck', routerLink: '/main/setting/deliverytypes/list', children: [] },
    { id: '', code: 'institutions', name: '', icon: 'building', routerLink: '/main/institutions/list', children: [] },
    { id: '', code: 'productsandservices', name: '', icon: 'shop', routerLink: '/main/productsandservices/list', children: [] },
    { id: '', code: 'clients', name: '', icon: 'users', routerLink: '/main/clients/list', children: [] },
    { id: '', code: 'sales', name: '', icon: 'money-bill', routerLink: '/main/sales/list', children: [] },
    { id: '', code: 'profile', name: 'Perfil', icon: '', routerLink: '/main/profile', children: [] },
    { id: '', code: 'recognition', name: '', icon: 'microchip-ai', routerLink: '/main/recognition/home', children: [] },
    { id: '', code: 'inventory', name: '', icon: 'box', routerLink: '/main/inventory/list', children: [] },
    { id: '', code: 'suppliers', name: '', icon: 'truck ', routerLink: '/main/suppliers/list', children: [] },
  ]);
  private listPermissionClientTemp = signal<ModulesInterface[]>([
    { id: '', code: 'application', name: 'Pedidos', icon: 'clipboard', routerLink: '/main/application', children: [] },
    { id: '', code: 'recognition', name: '', icon: 'microchip-ai', routerLink: '/main/recognition/home', children: [] },
  ]);

  public modules = computed(() => this.modulesTemp());
  public listPermission = computed(() => this.listPermissionTemp());
  public listPermissionClient = computed(() => this.listPermissionClientTemp());

  public treeSelectPermissions(permissions: PermissionInterface[]): TreeNode[] {
    const listModules: TreeNode[] = [];
    const modules = this.modules();
    const modulesMap = new Map(modules.map(module => [module.code, module]));
    const parentModule = modulesMap.get('cog');

    for (let index = 0; index < modules.length; index++) {
      const element = modules[index];
      const moduleResp = permissions.find(module => module.moduleAction.module.code === element.code);

      if (!moduleResp && parentModule) {
        for (let permIndex = 0; permIndex < permissions.length; permIndex++) {
          const elementPermissions = permissions[permIndex];
          const child = parentModule.children.find(child => child.code === elementPermissions.moduleAction.module.code);

          if (child) {
            const exists = listModules.some(module => module.key === elementPermissions.moduleAction.module.code);

            if (!exists) {
              const data: TreeNode = {
                key: elementPermissions.moduleAction.module.code,
                label: elementPermissions.moduleAction.module.name,
                icon: `pi pi-${child.icon}`,
                expanded: false,
                children: [],
                data: []
              };
              listModules.push(data);
            }
          }
        }
      } else if (moduleResp) {
        const data: TreeNode = {
          key: moduleResp.moduleAction.module.code,
          label: moduleResp.moduleAction.module.name,
          icon: `pi pi-${element.icon}`,
          expanded: false,
          children: [],
          data: []
        };
        listModules.push(data);
      }
    }

    for (const permission of permissions) {
      const moduleCode = permission.moduleAction.module.code;
      const module = listModules.find(mod => mod.key === moduleCode);
      if (module) {
        const action: ActionInterface = {
          active: (permission.active) ? true : false,
          inactive: (!permission.active) ? true : false,
          code: permission.moduleAction.action.code,
          id: permission.moduleAction.action.id,
          idModule: permission.moduleAction.module.id,
          idPermission: permission.id,
          name: permission.moduleAction.action.name,
        };
        module.data.push(action);
      }
    }

    return listModules;
  }

  permission(modules: ModulesInterface[]): ModulesInterface[] {
    const listModules: ModulesInterface[] = [];

    const moduleProfile = listModules.find(module => module.code === 'profile');
    const profile = this.listPermission().find(module => module.code === 'profile');

    if (!moduleProfile && profile) {
      const data: ModulesInterface = {
        code: profile.code,
        id: profile.id,
        name: profile.name,
        icon: profile.icon,
        routerLink: profile.routerLink,
        children: []
      }
      listModules.push(data);
    }

    for (let index = 0; index < modules.length; index++) {
      const element = modules[index];
      const module = this.listPermission().find(module => module.code === element.code);

      if (module) {
        const data: ModulesInterface = {
          ...element,
          icon: module.icon,
          routerLink: module.routerLink,
          children: []
        }
        listModules.unshift(data);
      }

    }
    return listModules;
  }

  moduleSidebar(modules: ModulesInterface[]): ModulesInterface[] {
    const listModules: ModulesInterface[] = [];
    const listModulesChildren: ModulesInterface[] = [];

    for (let index = 0; index < modules.length; index++) {
      const element = modules[index];
      const module = this.modules().find(module => module.code === element.code);

      if (!module) {
        const parentModule = this.modules().find(module => module.code === 'cog');
        if (parentModule) {

          const children = parentModule.children.find(module => module.code === element.code);
          if (children) {
            const data: ModulesInterface = {
              ...element,
              icon: children.icon,
              routerLink: children.routerLink,
              children: []
            }
            listModulesChildren.push(data);
          }
        }
      } else {
        const data: ModulesInterface = {
          ...element,
          icon: module.icon,
          routerLink: module.routerLink,
          children: []
        }
        listModules.push(data);
      }
    }

    if (listModulesChildren.length > 0) {
      const dataParent: ModulesInterface = {
        ...this.modules()[3],
        children: listModulesChildren
      }
      listModules.splice(3, 0, dataParent);
    }
    return listModules;
  }

  modulesClient(modules: ModulesInterface[]): ModulesInterface[] {
    const listModule: ModulesInterface[] = [];
    for (const module of modules) {
      const iconObject = this.listPermissionClient().find(item => item.code === module.code);
      if (iconObject) {
        const dataModule: ModulesInterface = {
          code: module.code,
          id: module.id,
          name: module.name,
          icon: iconObject.icon,
          routerLink: iconObject.routerLink,
          children: []
        };
        listModule.push(dataModule);
      }
    }
    return listModule;
  }
}

